import { classNames } from "@/Utils/theme";
import { ButtonHTMLAttributes } from "react";

export default function PrimaryButton({
    className = "",
    disabled,
    children,
    preset = "primary",
    small,
    outline,
    ...props
}: ButtonHTMLAttributes<HTMLButtonElement> & {
    preset?: "primary" | "danger";
    small?: boolean;
    outline?: boolean;
}) {
    let presetClasses = {
        primary:
            "bg-primary text-white  hover:bg-primary/70 focus:bg-primary/70 active:bg-primary focus:ring-primary justify-center ",
        danger: "bg-red-500 text-white  hover:bg-red-500/70 focus:bg-red-500/70 active:bg-red-500 focus:ring-primary ",
    };

    const smallClass = "!px-3 py-1 text-xs";

    if (small) {
        presetClasses = {
            primary: classNames(presetClasses["primary"], smallClass),
            danger: classNames(presetClasses["danger"], smallClass),
        };
    }

    if (outline) {
        presetClasses = {
            primary: classNames(
                presetClasses["primary"],
                "border-w-2 !border-primary !bg-white !text-primary hover:bg-primary/10 focus:bg-primary/10 active:bg-primary/20 focus:ring-primary"
            ),
            danger: classNames(
                presetClasses["danger"],
                "border border-pink-500 !text-red-500 bg-white hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/20 focus:ring-primary"
            ),
        };
    }

    return (
        <button
            {...props}
            className={
                `inline-flex items-center px-5 py-2  border border-transparent rounded font-semibold text-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 transition ease-in-out duration-150 ${
                    presetClasses[preset]
                } ${disabled && "opacity-25"} ` + className
            }
            disabled={disabled}
        >
            {children && (
                <div className="flex place-content-center place-items-center whitespace-pre">
                    {children}
                </div>
            )}
        </button>
    );
}
